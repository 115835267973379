import React, { Component } from "react";

class Header extends Component {

  render() {
    return <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="flex-row col-sm-12 col-lg-10">
            <div className="flex-large">
            <footer>
            <hr/>

              <div className="alignleft">Den Digitala Rebusförmedlaren</div>
              <div className="alignright">
                &copy; Henric, 2024
              </div>
            </footer>
            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default Header;
