import React from 'react';

export default function CorrectNextRebus(props: {nextRebus: string, isFinal: boolean, nextRebusId: number}) {
  return (<AlertDismissible nextRebus={props.nextRebus} isFinal={props.isFinal} nextRebusId={props.nextRebusId} />);
};

function AlertDismissible(props: {nextRebus: string, isFinal: boolean, nextRebusId: number}) {

  if (props.isFinal) {
    return (
      <div className="alert alert-success" role="alert">
        <h3>Rätt</h3>
        <p>
          Du har angett rätt svar. Detta var den sista uppgiften. Ta dig till målet vid <b>{props.nextRebus}</b>.
        </p>
      </div>
    );
  } else {
    return (
      <div className="alert alert-success" role="alert">
        <h3>Rätt</h3>
        <p>Nästa rebus (nummer: {props.nextRebusId}) att lösa är: <b>{props.nextRebus}</b><br/>
        Fyll i det i rutan högst upp.⏫
        </p>
      </div>
    );
  }
}
