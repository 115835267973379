import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faPlusCircle, faQuestionCircle, faStar} from '@fortawesome/free-solid-svg-icons';

function Navigation() {

  return <>
    <Navbar className="color-nav" expand="md">
      <Navbar.Brand href="/">Rebusknally</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/information">
            <FontAwesomeIcon icon={faInfoCircle} className="row-icon" />
            Information</Nav.Link>
          <Nav.Link href="/registration">
            <FontAwesomeIcon icon={faPlusCircle} className="row-icon"/>
            Anmälan</Nav.Link>
          <Nav.Link href="/ddr">
            <FontAwesomeIcon icon={faQuestionCircle} className="row-icon"/>
            DDR</Nav.Link>
          <Nav.Link href="/halloffame">
            <FontAwesomeIcon icon={faStar} className="row-icon"/>
            Hall of Fame</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>;
}

export default Navigation;
