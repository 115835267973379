import React from 'react';
import Image from 'react-bootstrap/Image';
import { RebusStage } from '../../../server/types/RebusDto';
import { Configuration } from '../config';

export default function CorrectSolution(props: {solvedRebus: RebusStage}) {
  return (<AlertDismissible solvedRebus={props.solvedRebus}/>);
};

function AlertDismissible(props: {solvedRebus: RebusStage}) {

  return (<div className="alert alert-success" role="alert">
    <h3>Rätt</h3>
    <div>
      Din lösning stämmer. För att få nästa rebus, ta dig till <b>{props.solvedRebus.nextRebusAt} </b> och lös uppgiften där.
      <Image src={Configuration.ImagesEndpoint + props.solvedRebus.nextRebusImageLink} alt="Nästa rebus" fluid />
      <div>
        <h6>Fråga</h6>
        {props.solvedRebus.instructions}⬇️
      </div>
    </div>
  </div>);
}