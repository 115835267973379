import React, {Component, Fragment} from "react";
import CorrectNextRebus from './CorrectNextRebus';
import WrongSolution from './WrongSolution';
import LaddaButton, {S, EXPAND_RIGHT} from 'react-ladda';
import axios from 'axios';
import { Configuration } from "../config";
import { RealLifeAnswerCorrectReply } from "../../../server/types/RebusDto";

export interface FindNextRebusProps {
  setDisplayMapToNextRebus: (s: boolean) => void;
  displayNextRebus: boolean;
  setDisplayNextRebus: (s: boolean) => void;
  teamName: string;
  isValid: () => boolean;
  inputRef: React.RefObject<HTMLInputElement>;
}

export default class FindNextRebus extends Component<FindNextRebusProps> {

  state = {
    realLifeAnswer: "",
    nextRebusId: 0,
    correct: false,
    loading: false,
    isFinal: false,
    rebus: "",
  };

  componentDidMount() {
    console.log("FindNextRebus initiated");
  };

  handleRealLifeAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({realLifeAnswer: event.target.value})
  };

  handleButtonClick = (evt: React.MouseEvent<HTMLElement>) => {

    evt.preventDefault();

    if (!this.props.isValid()) {
        this.props.inputRef.current?.scrollIntoView();
        this.props.inputRef.current?.focus();
        return;
    }

    this.setState({loading: true, resultShouldBeShown: false})

    axios.get<RealLifeAnswerCorrectReply | null>(Configuration.RebusEndpoint, {
      params: {
        realLifeAnswer: this.state.realLifeAnswer.trim().replace(/[.:]/g,""), // Remove punctuation for answers like 12:30
        teamName: this.props.teamName
      }
    }).then((response) => {
        this.props.setDisplayNextRebus(true);

        if (response.data != null) {
            this.setState({correct: true, rebus: response.data.rebus, isFinal: response.data.isFinal, nextRebusId: response.data.id });
            this.props.setDisplayMapToNextRebus(false);
        } else {
            this.setState({correct: false});
        }
    }).catch((error) => {
      console.log(error);
      this.setState({correct: false});
      this.props.setDisplayNextRebus(true);
    }).then(() => {
      this.setState({resultShouldBeShown: true, loading: false});
      window.scrollTo(0, 2000);
    });
  };

  render() {
    return (<Fragment>
      <div>
        <h4>Få nästa rebus</h4>
        <form>
          <div className="row col-sm-12">
            <label>Ange det du fått instruktioner om - årtal då huset byggdes, antal fönster, eller kanske ett namn. Om det är rätt, kommer du att få en rebus som du löser för att hitta till nästa plats.</label>
            <input type="text" className="form-control col-sm-12 col-md-8 col-lg-6" value={this.state.realLifeAnswer} onChange={this.handleRealLifeAnswerChange} placeholder="Skriv in ditt förslag"/>
          </div>
          <LaddaButton loading={this.state.loading} onClick={this.handleButtonClick} data-size={S} data-style={EXPAND_RIGHT} data-spinner-size={30} data-spinner-color="#ddd" data-spinner-lines={12}>
            Kontrollera
          </LaddaButton>
        </form>
        <div>
          {
            this.props.displayNextRebus
              ? this.state.correct
                ? <CorrectNextRebus nextRebus={this.state.rebus} isFinal={this.state.isFinal} nextRebusId={this.state.nextRebusId}/>
                : <WrongSolution/>
              : <Fragment/>
          }
        </div>
      </div>
    </Fragment>);
  }
};
