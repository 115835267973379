import React, {Fragment, useRef, useState} from 'react';
import FindNextRebus from './FindNextRebus';
import SolveRebus from './SolveRebus';
import '../App.css';


function DigitalRebusHelper() {

  const [displayMapToNextRebus, setDisplayMapToNextRebus] = useState<boolean>(false);
  const [displayNextRebus, setDisplayNextRebus] = useState<boolean>(false);
  const [teamName, setTeamName] = useState<string>("");

  const language = {
    enterTeamName: "Skriv in lagnamn"
  }

  const inputBaseClassName = "form-control col-sm-12 col-md-8 col-lg-6";

  const inputRef = useRef<HTMLInputElement>(null);

  const isValid = () => {
    if (teamName.length > 0) {
        return true;
    }
    if (inputRef.current) {
        inputRef.current.className = `${inputBaseClassName} required`;
    }
    return false;
  }

  return (<Fragment>
    <div className="container">
      <div className="row justify-content-center">
        <div className="flex-row col-sm-12 col-md-10">
          <div className="flex-large">
            <form className="teamname-form">
                <label>Lagnamn*</label>
                <input
                    type="text"
                    className={inputBaseClassName}
                    value={teamName}
                    // required
                    ref={inputRef}
                    onChange={(e) => {
                        if (inputRef.current) {
                            inputRef.current.className = inputBaseClassName;
                        }
                        setTeamName(e.currentTarget.value)}
                    }
                    placeholder={language.enterTeamName}/>
            </form>

            < hr />

            <SolveRebus
              displayMapToNextRebus={displayMapToNextRebus}
              setDisplayMapToNextRebus={setDisplayMapToNextRebus}
              setDisplayNextRebus={setDisplayNextRebus}
              teamName={teamName}
              isValid={isValid}
              inputRef={inputRef}
              />

            < hr />
            <FindNextRebus
              setDisplayMapToNextRebus={setDisplayMapToNextRebus}
              displayNextRebus={displayNextRebus}
              setDisplayNextRebus={setDisplayNextRebus}
              teamName={teamName}
              inputRef={inputRef}
              isValid={isValid}
              />
          </div>
        </div>
      </div>
    </div>
  </Fragment>);
}

export default DigitalRebusHelper;
