import React, {Component, Fragment} from "react";
import CorrectSolution from './CorrectSolution';
import WrongSolution from './WrongSolution';
import LaddaButton, {S, EXPAND_RIGHT} from 'react-ladda';
import axios from 'axios';
import { Configuration } from '../config';
import { RebusStage } from '../../../server/types/RebusDto';
import StatusDto from '../../../server/types/StatusDto';
import Alert from "./common/Alert";
import Collapse from 'react-bootstrap/Collapse';

export interface SolveRebusProps {
  displayMapToNextRebus: boolean;
  setDisplayMapToNextRebus: (s: boolean) => void;
  setDisplayNextRebus: (s: boolean) => void;
  teamName: string;
  isValid: () => boolean;
  inputRef: React.RefObject<HTMLInputElement>;
}

class SolveRebus extends Component<SolveRebusProps> {

  myRef = React.createRef<HTMLDivElement>();

  state = {
    id: 1,
    solution: "",
    correct: false,
    solvedRebus: {} as RebusStage,
    loading: false,
    rebusCount: 0,
    isLiveMode: false,
  };

  // const {displayMapToNextRebus} = this.props;
  componentDidMount() {
    axios.get<StatusDto>(Configuration.StatusEndpoint).then((response) => {
      const data = response.data;
      this.setState({ rebusCount: data.rebusCount, isLiveMode: data.isLiveMode });
    }).catch((error) => {
      console.log(error);
    }).then(() => {});
  };

  handleIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({id: parseInt(event.target.value)});
  }

  handleSolutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({solution: event.target.value})
  };

  handleButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!this.props.isValid()) {
        this.props.inputRef.current?.scrollIntoView();
        this.props.inputRef.current?.focus();
        return;
    }
    this.setState({loading: true});
    this.props.setDisplayMapToNextRebus(false);

    axios.get<RebusStage | null>(Configuration.RebusEndpoint, {
      params: {
        solution: this.state.solution.trim().replace(/[.:]/g,""), // Remove punctuation for answers like 12:30
        id: this.state.id,
        teamName: this.props.teamName
      }
    }).then((response) => {
      if (response.data) {
        this.setState({correct: true, solvedRebus: response.data})
        this.props.setDisplayNextRebus(false);
      } else {
        this.setState({correct: false});
      }
    }).catch((error) => {
      console.log(error);
      this.setState({correct: false});
    }).then(() => {
      this.setState({loading: false});
      this.props.setDisplayMapToNextRebus(true);
      if (this.myRef.current) {

        setTimeout(
          () => this.myRef.current?.scrollIntoView(), 
          500
        );
      }
    });
  };

  language = {
    solveRebusHeader: "Lös en rebus",
    solveRebusText: "Välj vilken rebus det gäller, fyll i din lösning och tryck \"Kontrollera\". Om din lösning är rätt, kommer det att stå vad du ska göra för att få nästa rebus.",
    rebusPlaceHolder: "Skriv in din lösning",
    alertHeader: "Demo",
    alertText: "DDR kör just nu i demoläge. Lösningarna återfinns på en karta över Östergötland. Den första rebusen är: ",
    alertFirstRebus: "NKLÖPING",
    controlButtonText: "Kontrollera"
  };

  render() {
    const rebusOptions = [];
    const { displayMapToNextRebus } = this.props;

    for (var index = 1; index <= this.state.rebusCount; index++) {
      rebusOptions.push(<option key={index} value={index}>{index}</option>)
    }

    return (<Fragment>
      <div>
        <h4>{this.language.solveRebusHeader}</h4>
        {!this.state.isLiveMode && <Alert type="primary" header={this.language.alertHeader} text={this.language.alertText} rebus={this.language.alertFirstRebus}/>}
        <form>
            <div className="row col-sm-12">
            <label>{this.language.solveRebusText}</label>
              <select value={this.state.id} onChange={this.handleIdChange} className="form-control col-sm-2">
                {rebusOptions}
              </select>
              <input type="text" className="form-control col-sm-9 col-md-8 col-lg-6 offset-sm-1" value={this.state.solution} onChange={this.handleSolutionChange} placeholder={this.language.rebusPlaceHolder}/>
          </div>
          <LaddaButton loading={this.state.loading} onClick={this.handleButtonClick} data-size={S} data-style={EXPAND_RIGHT} data-spinner-size={30} data-spinner-color="#ddd" data-spinner-lines={12}>
            {this.language.controlButtonText}
          </LaddaButton>
        </form>
        <div ref={this.myRef}>
        <Collapse in={displayMapToNextRebus}>
        <div id="example-collapse-text row col-sm-12">
        {
            this.state.correct
              ? <CorrectSolution solvedRebus={this.state.solvedRebus}/>
              : <WrongSolution/>
          }
        </div>
      </Collapse>
        </div>

      </div>
    </Fragment>);
  }
}

export default SolveRebus;
