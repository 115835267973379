import React, { useState } from 'react';
import Alert from './common/Alert';
import LaddaButton, {S} from 'react-ladda';

export default function TestComponents() {

  return <>
      <div className="container">
      <div className="row justify-content-center">
        <div className="flex-row col-sm-12 col-md-10">
          <div className="flex-large test-components">
            <TestAlerts />
            <TestGeolocation />
          </div>
        </div>
      </div>
    </div>
    </>
};

function TestAlerts() {


  const [type, setType] = useState<"primary" | "success" | "warning" | "danger">("primary");


  return <>
    <h2>Alert component</h2>
    <button onClick={() => setType(type === "primary" ? "success" : (type === "success" ? "warning" : (type === "warning" ? "danger" : "primary")))}>Type: <span>{type}</span></button>
    <Alert
      header="Header"
      text={"This is a " + type + " alert"}
      type={type} />
  </>
}

function TestGeolocation() {


  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  let geolocation = navigator.geolocation;


  return <>
    <h2>Geolocation</h2>
    <LaddaButton onClick={() => {
      geolocation.getCurrentPosition((pos) => {
        console.log(pos);
        setLocation(pos)}
      );
    }} data-size={S}>Visa position</LaddaButton>
    <p>Longitude: {location?.coords.longitude}</p>
    <p>Latitude: {location?.coords.latitude}</p>
  </>
}