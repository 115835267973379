import React, { useEffect, useMemo, useState } from "react";
import { Configuration } from "../config";
import DdrEntry from "../../../server/types/DdrEntry";
import axios from "axios";
import StatusDto from "../../../server/types/StatusDto";

export default function Monitoring() {

    const [ddrEntries, setDdrEntries] = useState<DdrEntry[] | null>(null);
    const [rebusArray, setRebusArray] = useState<number[]>([]);

    useEffect(() => {
        axios.get<StatusDto>(Configuration.StatusEndpoint).then((response) => {
            const data = response.data;
            var rebusIds = [];

            for (var i = 1; i <= data.rebusCount; i++) {
                rebusIds.push(i);
            }
            setRebusArray(rebusIds);
        }).catch((error) => {
            console.log(error);
        }).then(() => { });
    }, []);

    useEffect(() => {
        const loadData = () => {
            axios.get<DdrEntry[]>(Configuration.DdrEntryEndpoint, {
            }).then((response) => {
                setDdrEntries(response.data);
            }).catch((error) => {
            }).then(() => {
            });
        }
        loadData();

        const intervalId = setInterval(loadData, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const [groupedEntries, setGroupedEntries] = useState<Record<string, DdrEntry[]>>({});
    useMemo(() => {

        if (ddrEntries == null) {
            return null;
        }
        const results = groupBy(ddrEntries, e => e.teamName);
        setGroupedEntries(results);

    }, [ddrEntries]);

    return <>
        <div className="container">
            <div className="row justify-content-center">
                <div className="flex-row col-sm-12 col-md-10">
                    <div className="flex-large">
                        <h2>Monitorering</h2>
                        <div>Tabellen visar antal gissningar samt tidpunkt för senaste gissning. Starkare färg är gissningar gjorda de senaste 2 minuterna.</div>
                        <div className="monitoring-table">
                            <div className="monitoring-header">
                                <div className="monitoring-row">
                                    <div className="monitoring-cell">Info</div>
                                    {rebusArray.map((r, i) => {
                                        return <RenderHeaderCell key={i} rebusId={r} />
                                    })}
                                </div>
                            </div>
                            <div className="monitoring-body">
                                {Object.entries(groupedEntries).map(([teamName, entries], i) =>
                                    <div className="monitoring-row" key={i}>
                                        <div className="monitoring-cell">{teamName}</div>
                                        {rebusArray.map((r, i) => {
                                            return <RenderTeamCell
                                                key={i}
                                                rebusId={r}
                                                entries={entries.filter(e => e.rebusId === r)} />
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

function RenderHeaderCell({ rebusId }: { rebusId: number }) {
    return <div className="monitoring-cell">Sträcka {rebusId}</div>
}

function RenderTeamCell({ rebusId, entries }: { rebusId: number, entries: DdrEntry[] }) {

    if (entries.length === 0) {
        return <div className="monitoring-cell"></div>;
    }

    const isSuccess = entries.filter(e => e.success).length > 0;
    const isFailure = entries.filter(e => !e.success).length > 0;


    entries.sort((a, b) => Date.parse(b.eventUtc) - Date.parse(a.eventUtc));
    const lastEntry = entries[0];
    const lastEntryDate = new Date(lastEntry.eventUtc);
    const now = new Date();
    var secondsDiff = (now.getTime() - lastEntryDate.getTime()) / 1000;
    const isRecent = secondsDiff < 120;
    let className = isSuccess ? "success" : (isFailure ? "failure" : "");
    if (isRecent) {
        className = `${className} recent`;
    }

    return <div className={`monitoring-cell ${className}`.trim()}><span>{entries.length}</span> <span>({lastEntryDate.toLocaleTimeString("sv-SE")})</span></div>
}


export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);