import React from 'react';
import Alert from './common/Alert';

export default function WrongSolution() {

  return <Alert
  header="Fel"
  text="Din lösning stämmer inte. Kontrollera att du valt rätt kontroll för
          din lösning, eller att du angett det som frågas efter."
  type="danger" /> ;
};