import React, { Component } from "react";

interface HallOfFameRecord {
  year: number;
  teamName: string;
  score: number;
  publicFolderName: string;
}

class HallOfFame extends Component {

  records = [
    {
      year: 2024,
      teamName: "Katlas Kompisar",
      score: 5,
      publicFolderName: "knally2024"
    },{
      year: 2022,
      teamName: "Knial & Error",
      score: 68,
      publicFolderName: "knally2022"
    },
  ] as HallOfFameRecord[];
  render() {
    return <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="flex-row col-sm-12 col-md-10">
            <div className="flex-large">
              <h1>Hall of Fame</h1>
              {this.records.map((r, i) => <RenderRecord record={r} key={i} />)}
            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default HallOfFame;


function RenderRecord({ record }: { record: HallOfFameRecord }) {
  const port = window.location.port.length > 0 ? `:${window.location.port}` : "";
  const folderUrl = `${window.location.protocol}//${window.location.hostname}${port}/${record.publicFolderName}`;
  return <div className="halloffame-record">
          <h4>{record.year}</h4>
          <div>Segrare: {record.teamName}, {record.score} prickar</div>
          <a href={folderUrl}>Resultat {record.year}</a>
        </div>
}
