import React from 'react';

export interface AlertProps {
  type: "primary" | "success" | "warning" | "danger";
  header: string;
  text: string;
  rebus?: string;
}

export default function Alert(props: AlertProps) {

  const className =`alert alert-${props.type}`;
  return <>
    <div className={className}>
      <h5>{props.header}</h5>
      <span>{props.text}</span>
      <b>{props.rebus}</b>
    </div>
  </>;
};