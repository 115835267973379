import React, { Component } from "react";

class Registration extends Component {

  render() {
    return <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="flex-row col-sm-12 col-md-10">
            <div className="flex-large">
              <h1>Anmälan</h1>
              <p>Anmälan sker via <a href="https://forms.gle/uszfKChPkPjdWPkM8">denna Google-forms-länk</a>. Ett preliminärt knally-PM kommer att skickas ut till lagledarna i god tid innan knallyt. </p>
            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default Registration;
