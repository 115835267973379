import React, {Fragment} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Information from './components/Information';
import Registration from './components/Registration';
import DigitalRebusHelper from './components/DigitalRebusHelper';
import HallOfFame from './components/HallOfFame';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import './App.css';
import { Configuration } from './config';
import TestComponents from './components/TestComponents';
import Monitoring from './components/Monitoring';

function App() {

  // When the API is called for the first time in a while,
  // it takes 5-10 seconds to start. Subsequent calls are fast.
  // By calling the API as soon as the app starts, real use
  // (for solving rebuses) should be faster
  // Taken from https://stackoverflow.com/a/53219430
  React.useEffect(() => {
    fetch(Configuration.StatusEndpoint)
  }, []); // <-- Have to pass in [] here!

  return (<Fragment>
    <BrowserRouter>
        <div>
          <Navigation/>
          <Switch>
            <Route path="/" component={Information} exact/>
            <Route path="/information" component={Information}/>
            <Route path="/registration" component={Registration}/>
            <Route path="/ddr" component={DigitalRebusHelper}/>
            <Route path="/halloffame" component={HallOfFame}/>
            <Route path="/testcomponents" component={TestComponents}/>
            <Route path="/monitoring" component={Monitoring}/>
          </Switch>
        </div>
      </BrowserRouter>
      <Footer />
</Fragment>);
}

export default App;
