import React, { Component } from "react";

class Information extends Component {

  render() {
    return <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="flex-row col-sm-12 col-md-10">
            <div className="flex-large">
            <h1>Information</h1>
            <p>Välkomna till Rebusknally!</p>

            <p>Knallyledningen ber att få önska er välkomna till Rebusknally, söndagen den 18:e augusti år 2024.</p>

            <p>Knallyt startar 11:00, och beräknas ta 2,5-3 timmar och bli 4 km långt. Knallyt äger rum i Linköpings södra delar, mer information kommer att skickas ut till anmälda lag.</p>

            <p>Rebusknally är en sport utan svett och utan bilar, men med vissa
            likheter med rebusrally. I ett rebusknally knallar ni runt (därav
              namnet) en lagom lång sträcka i en maklig takt. Utmed vägen
              plockar ni plock, löser rebusar och klurar på pyssel. Liksom
              rebusrally är rebusknally en tämligen analog aktivitet.
              Det som premieras är kunskap, fantasi, uppmärksamhet och
              samarbetsförmåga. Alla uppgifter som ingår i ett knally belastas
              med prickar. Ju bättre resultat, desto färre prickar.
              Det lag som samlat på sig färst prickar under dagen vinner.</p>

            <p>Max antal deltagare i ett och samma lag är 5. Temat är huvudbonader. Klä ut dig och ditt lag enligt temat
            och var med och tävla om stil(minus)prickar. Aktiviteten beräknas
              ta 2-3 timmar och kommer att ske utomhus. Start och mål kommer
              att vara i Linköping och vara lätt att ta sig
              till med kollektivtrafik.</p>
            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default Information;
